import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import type { ExpandableProps } from './Expandable.types';

import styles from './Expandable.module.scss';

export const Expandable = ({
  caption,
  children,
  id,
  onClick,
}: ExpandableProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isNativeImplementation, setIsNativeImplementation] = useState(true);
  const [height, setHeight] = useState('0');

  const accordion = useRef<HTMLDetailsElement | null>(null);
  const content = useRef<HTMLDivElement | null>(null);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    onClick?.(e, isExpanded);
    setIsExpanded(!isExpanded);
  };

  useEffect(() => setIsNativeImplementation(false), []);

  useEffect(() => {
    const expandableComponent = accordion.current;

    const isContentExpanded = content.current && isExpanded;
    const isAccordionOpen = expandableComponent && isExpanded;
    const isAccordionClosed = expandableComponent && !isExpanded;

    if (isAccordionOpen) {
      expandableComponent.open = isExpanded;
    }

    if (isAccordionClosed) {
      timer.current = setTimeout(() => {
        expandableComponent.open = isExpanded;
      }, 400);
    }

    setHeight(isContentExpanded ? `${content?.current?.scrollHeight}px` : '0');

    return () => {
      clearTimeout(timer.current);
    };
  }, [isExpanded]);

  return (
    <div className={cx(styles.component, {
      [styles.expanded]: isExpanded,
      [styles.nativeComponent]: isNativeImplementation,
    })}>
      <details
        ref={accordion}
        className={styles.accordion}
        {...{ id }}
      >
        <summary
          onClick={(e) => handleClick(e)}
          tabIndex={0}
        >
          <div className={styles.caption}>
            <h3 className={styles.text}>{caption}</h3>
            <span className={styles.icon} />
          </div>
        </summary>
        <div
          className={styles.contentWrapper}
          style={{ maxHeight: `${height}` }}
        >
          <div
            ref={content}
            className={styles.content}
            tabIndex={-1}
          >
            {children}
          </div>
        </div>
      </details>
    </div>
  );
};
