import entities from './entities.json';

const entityMap = new Map(Object.entries(entities));

const getTextContent = (html: string) => html.replace(/<[^>]+?>/g, '');

const getEscapedContent = (content: string) =>
  content.replace(/&([^;]+?);/g, (entity, entityCode: string) => {
    if (entityMap.has(entityCode)) {
      return entityMap.get(entityCode)!;
    }

    const [, numericCode] = (/^#(\d+)$/).exec(entityCode) ?? [];

    if (numericCode !== undefined) {
      return String.fromCharCode(parseInt(numericCode));
    }

    return entity;
  });

export const getParsedContent = (content: string) => {
  try {
    return getEscapedContent(getTextContent(content));
  } catch (error) {
    return '';
  }
};
