import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { ArrowRightIcon } from '@motorway/mw-highway-code';
import { SIZE } from '@motorway/mw-highway-code/enums';

import { GuideData } from '../Guides.types';
import { getPartitionedExcerpt } from '../guidesHelper';

import styles from './Guide.scss';

type GuideProps = {
  readonly guide: GuideData;
  readonly onClick?: (guide: GuideData) => void;
};

export const Guide = forwardRef<HTMLAnchorElement, GuideProps>(({ guide, onClick }, ref) => {
  const excerptParts = getPartitionedExcerpt(guide.excerpt);

  return (
    <Link
      ref={ref}
      className={styles.component}
      data-testid='guide'
      onClick={() => {
        onClick?.(guide);
      }}
      to={guide.link}
    >
      <article>
        <h3 className={styles.title}>{guide.title}</h3>
        <time className={styles.date} dateTime={guide.date}>
          {new Date(guide.date).toLocaleDateString('en-GB')}
        </time>
        <p className={styles.detail}>
          <span className={styles.first}>
            {excerptParts[0]}
          </span>

          <span className={styles.last}>
            <span className={styles.text}>{excerptParts[1]}</span>
          </span>
          <ArrowRightIcon size={SIZE.XSMALL} />
        </p>
      </article>
    </Link>
  );
});
