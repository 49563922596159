import { WordpressGuideSummaryData } from 'Utilities/wordpress/@types';

import { getParsedContent } from '../../../../../../server/wordpress/parse/parse';

import { GuideData } from './Guides.types';

const MAX_CHAR = 26;

const truncate = (str: string, maxChars: number) => {
  if (str.length < maxChars) {
    return str;
  }
  const sliced = str.slice(0, maxChars);
  return sliced.slice(0, Math.min(sliced.length, sliced.lastIndexOf(' ')));
};

export const getPartitionedExcerpt = (content: string) => {
  const p1 = truncate(content, MAX_CHAR);
  const p2 = content.slice(p1.length).trim();
  return [p1, p2] as const;
};

const GUIDE_LINK_ORIGIN = 'https://blog.motorway.co.uk';

export const getMappedGuideData = (data: WordpressGuideSummaryData): GuideData => {
  const { excerpt, link, title, ...common } = data;
  return {
    excerpt: getParsedContent(excerpt.rendered),
    link: link.replace(GUIDE_LINK_ORIGIN, ''),
    title: getParsedContent(title.rendered),
    ...common,
  };
};

const GUIDE_COUNT = 4;

export const getLatestGuides = (
  guidesData: readonly WordpressGuideSummaryData[],
) => [...guidesData]
  ?.sort((a, b) => (a.date < b.date ? 1 : -1))
  .slice(0, GUIDE_COUNT);

export const getGuidesData = (
  guidesData: readonly WordpressGuideSummaryData[],
) => getLatestGuides(guidesData).map(getMappedGuideData);
