import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { RelatedGuidesProps } from './RelatedGuides.types';

import styles from './RelatedGuides.module.scss';

export const RelatedGuides = ({
  description = undefined,
  imgAlt = '',
  imgSrc = undefined,
  largeTheme = false,
  onClick: onClickHandler = undefined,
  title = undefined,
  url = '',
  wrapperClassName = undefined,
}: RelatedGuidesProps) => (
  <div className={cx(styles.relatedGuidesInnerContainer, wrapperClassName, { [styles.largeTheme]: largeTheme })}>
    <Link
      data-testid='relatedGuide'
      onClick={onClickHandler}
      to={url}
    >
      <div className={styles.thumbnailContainer}>
        {(imgSrc) ? (
          <img
            alt={imgAlt}
            height="192"
            loading="lazy"
            src={imgSrc}
            width="288"
          />
        ) : (null)}
      </div>
      <div className={styles.textContent}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
    </Link>
  </div>
);
