import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import blankSrc from './blank.gif';

import breakpoints from '../Breakpoints/Breakpoints.scss';
import styles from './ResponsiveImage.scss';

type ImageSize = {
  clientHeight?: number;
  clientWidth?: number;
}

const ResponsiveImage = ({
  imgProps,
  imgSrc,
  isStaticScreenSize = false,
}: { imgProps?: object; imgSrc?: string; isStaticScreenSize?: boolean }) => {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [screenSize, setScreenSize] = useState<string | undefined>();
  const blankImgRef = useRef<HTMLImageElement>(null);
  const blankImgSize = useRef<ImageSize>({ clientHeight: undefined, clientWidth: undefined });

  useEffect(() => {
    const { clientHeight, clientWidth }: { clientHeight?: number; clientWidth?: number } = blankImgRef.current || {};
    blankImgSize.current = { clientHeight, clientWidth };

    setScreenSize(`${globalThis.innerWidth + 1}px`);
  }, []);

  if (!imgSrc) {
    return (null);
  }

  if (!screenSize) {
    return (
      <picture className={styles.component}>
        <img ref={blankImgRef} src={blankSrc} {...imgProps} />
      </picture>
    );
  }

  const { clientHeight: h, clientWidth: w } = blankImgSize.current || {};

  const imgUrl = new URL(imgSrc);
  const queryParams = {
    ...Object.fromEntries(imgUrl.searchParams),
    auto: 'format',
    dpr: globalThis.devicePixelRatio || 1,
    h,
    w,
  };

  // @ts-ignore
  const constructUrl = ({ height, width }: { height?: number; width?: number } = {}) => `${imgUrl.origin}${imgUrl.pathname}?${new URLSearchParams({
    ...queryParams,
    h: height || queryParams.h,
    w: width || queryParams.w,
  }).toString()}`;

  const largeImage = constructUrl({ height: 1024, width: 1024 });

  const smallImage = constructUrl({ height: 640, width: 640 });

  return (
    <picture className={cx(styles.component, { [styles.loaded]: fullyLoaded })}>
      {isStaticScreenSize ? (
        <>
          <source media={`(min-width: ${breakpoints.breakpointTabletMobile})`} srcSet={largeImage} />
          <source srcSet={smallImage} />
        </>
      ) : (
        <>
          <source media={`(min-width: ${screenSize})`} srcSet={largeImage} />
          <source srcSet={constructUrl()} />
        </>
      )}
      <img
        {...imgProps}
        onLoad={() => setFullyLoaded(true)}
        src={largeImage}
      />
    </picture>
  );
};

export default ResponsiveImage;
