import cx from 'classnames';

import { GuideCollectionProps } from '../Guides.types';

import { Guide } from './Guide';

import styles from './GuideList.scss';

type Props = GuideCollectionProps

export const GuideList = ({ className, guides, onGuideClick, setGuideRef }: Props) => (
  <ol className={cx(styles.component, className)} data-testid='guideList'>
    {guides.map((guide, index) => (
      <li key={guide.id}>
        <Guide
          ref={(ref) => ref && setGuideRef?.(ref, index)}
          onClick={() => {
            onGuideClick?.(guide);
          }}
          {...{ guide }} />
      </li>
    ))}
  </ol>
);
